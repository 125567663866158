import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        themes: {
            light: {
                orange: '#f87e2b',
            },
        },
    },
});
