<template>
    <div
        class="scroll-top"
        @click="scrollTop"
    >
        <v-icon class="top-icon">
            mdi-arrow-up
        </v-icon>
        <div class="text">
            ＴＯＰ
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() {
    },
    methods: {
        scrollTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll-top {
  background-color: $color-orange;
  padding: 9px 10px;
  position: fixed;
  z-index: 30;
  bottom: 0;
  right: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top-icon {
    color: white;
    margin-bottom: 5px;
  }
  .text {
    color: white;
    font-weight: 700;
    font-size: 13px;
  }
}
</style>
