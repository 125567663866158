<template>
    <div class="home-block home-block5">
        <div class="title-block">
            <h2
                class="home-block-title"
                data-aos="fade-up"
            >
                {{ $t('home.block5.title') }}
            </h2>
            <h3
                class="home-block-subtitle"
                data-aos="fade-up"
            >
                {{ $t('home.block5.subtitle') }}
            </h3>
        </div>
        <div class="impact-block">
            <div class="line">
                <div
                    class="box"
                    data-aos="fade-up"
                >
                    <img
                        src="../../assets/home/impact-1.png"
                        alt=""
                    >
                    <div class="item">
                        <div class="item-title">
                            Impact Ventures
                        </div>
                        <router-link
                            :to="{name: 'Invention'}"
                            class="button"
                            data-aos="fade-up"
                        >
                            {{ $t('home.block5.buttonName[0]') }}
                            <v-icon class="arrow">
                                mdi-arrow-right
                            </v-icon>
                        </router-link>
                    </div>
                </div>
                <div
                    class="box"
                    data-aos="fade-up"
                >
                    <img
                        src="../../assets/home/impact-2.png"
                        alt=""
                    >
                    <div class="item">
                        <div class="item-title">
                            Impact Academy
                        </div>
                        <router-link
                            :to="{name: 'AcademyIndex'}"
                            class="button"
                            data-aos="fade-up"
                        >
                            {{ $t('home.block5.buttonName[1]') }}
                            <v-icon class="arrow">
                                mdi-arrow-right
                            </v-icon>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="line">
                <div
                    class="box"
                    data-aos="fade-up"
                >
                    <img
                        src="../../assets/home/impact-3.png"
                        alt=""
                    >
                    <div class="item">
                        <div class="item-title">
                            Impact Study
                        </div>
                        <router-link
                            :to="{name: 'StudyIndex'}"
                            class="button"
                            data-aos="fade-up"
                        >
                            {{ $t('home.block5.buttonName[2]') }}
                            <v-icon class="arrow">
                                mdi-arrow-right
                            </v-icon>
                        </router-link>
                    </div>
                </div>
                <div
                    class="box"
                    data-aos="fade-up"
                >
                    <img
                        src="../../assets/home/impact-4.png"
                        alt=""
                    >
                    <div class="item">
                        <div class="item-title">
                            Impact News
                        </div>
                        <router-link
                            :to="{name: 'NewsIndex'}"
                            class="button"
                            data-aos="fade-up"
                        >
                            {{ $t('home.block5.buttonName[3]') }}
                            <v-icon class="arrow">
                                mdi-arrow-right
                            </v-icon>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.home-block5 {
  background-image: url('../../assets/home/block-bg-top.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0;
  .home-block-title {
    margin-bottom: 30px;
  }

  .title-block {
    margin-bottom: 80px;
  }

  .impact-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-gray;

    .line {
      display: flex;
      width: 775px;
      justify-content: space-between;
      .box {
        display: flex;
        align-items: center;

        img {
          width: 70px;
          height: 105px;
          margin-right: 30px;
        }

        .item {
          .item-title {
            font-weight: 700;
            font-size: 21px;
            font-weight: 700px;
            line-height: 1;
            margin-bottom: 9px;
            color: $color-gray;
          }
          .button {
            width: 180px;
            height: 41px;
            color:#404040;
            border: 1px solid #404040;
            border-radius: 180px;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 21px;
            letter-spacing: 0.1em;
            line-height: 1;
            cursor: pointer;

            .arrow {
              margin-left: 5px;
              color: $color-gray;
            }
          }
        }

        .item:hover {
          .item-title {
            color: $color-orange;
          }
          .button {
            background-color: $color-gray;
            color: $color-orange;

            .arrow {
              color: $color-orange;
            }
          }
        }
      }
    }

    .line:first-child {
      margin-bottom: 80px;
    }
  }
}
@media screen and (max-width: 960px) {
  .home-block5 {
    .impact-block {
      .line {
        width: 100vw;
        flex-direction: column;
        align-items: center;

        .box {
          margin-bottom: 60px;
        }
      }

      .line:first-child {
        margin-bottom: 0px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home-block5 {
    padding: 75px 0;

    .block-title {
      margin-bottom: 12px;
    }

    .title-block {
      margin-bottom: 55px;
    }

    .impact-block {
      .line {
        .box {
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>
