<template>
    <div class="marquee font-size-large font-weight-bold line-height-1">
        <div id="div1">
            {{ $t('home.marquee') }}
        </div>
        <div id="div2">
            {{ $t('home.marquee') }}
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.marquee {
  width: 100%;
  border-top: 2px solid #043d50;
  border-bottom: 2px solid #043d50;
  padding: 22px 0;
  color: $color-blue;
  overflow: hidden;
  white-space: nowrap;
}

#div1 {
  display: inline-block;
  animation: marquee 30s linear infinite;
}

#div2 {
  display: inline-block;
  animation: marquee2 30s linear infinite;
  animation-delay: 15s;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 600px) {
  .marquee {
    padding: 10px 0;
    font-size: 20px;
  }
}
</style>
