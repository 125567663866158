<template>
    <div class="home-block home-block2">
        <div
            class="box image-left box-1"
            data-aos="fade-up"
        >
            <img
                class="img is-desktop"
                src="../../assets/home/block2-1.jpg"
                alt=""
            >
            <img
                class="img is-mobile"
                src="../../assets/home/mobile/block2-1-mobile.jpg"
                alt=""
            >
            <div class="content">
                <div class="content-title">
                    {{ $t('home.block2[0].title') }}
                </div>
                <div class="text">
                    {{ $t('home.block2[0].text') }}
                </div>
            </div>
        </div>
        <div
            class="box image-right box-2"
            data-aos="fade-up"
        >
            <img
                class="img is-desktop"
                src="../../assets/home/block2-2.jpg"
                alt=""
            >
            <img
                class="img is-mobile"
                src="../../assets/home/mobile/block2-2-mobile.jpg"
                alt=""
            >
            <div class="content">
                <div class="content-title">
                    {{ $t('home.block2[1].title') }}
                </div>
                <div class="text">
                    {{ $t('home.block2[1].text') }}
                </div>
            </div>
        </div>
        <div
            class="box image-left box-3"
            data-aos="fade-up"
        >
            <img
                class="img is-desktop"
                src="../../assets/home/block2-3.jpg"
                alt=""
            >
            <img
                class="img is-mobile"
                src="../../assets/home/mobile/block2-3-mobile.jpg"
                alt=""
            >
            <div class="content">
                <div class="content-title">
                    {{ $t('home.block2[2].title') }}
                </div>
                <div class="text">
                    {{ $t('home.block2[2].text') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.home-block2 {
  .is-mobile {
    display: none;
  }
  .box {
    display: flex;
    .img {
      width: 50%;
    }

    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .content-title {
        font-weight: 700;
        font-size: 45px;
        line-height: 1;
        color: white;
        letter-spacing: 0.1em;
        margin-bottom: 18px;
        padding-bottom: 18px;
        border-bottom: 5px solid white;
      }
      .text {
        font-weight: 700;
        font-size: 21px;
        line-height: 2;
        letter-spacing: 0.1em;
        color: white;
        margin-top: 18px;
      }
    }
  }

  .box-1 {
    .content {
      background-color: $color-yellow;
    }
  }

  .box-2 {
    .content {
      background-color: $color-blue2;
    }
  }

  .box-3 {
    .content {
      background-color: $color-orange;
    }
  }

  .image-left {
    .content {
      align-items: flex-start;
      padding: 0 40px 0 15px;

      .text {
        text-align: left;
      }
    }
  }

  .image-right {
    flex-direction: row-reverse;
    .content {
      align-items: flex-end;
      padding: 0 15px 0 40px;

      .text {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .home-block2 {
    .box {

      .content {

        .content-title {
          font-size: 28px;
        }

        .text {
          font-size: 16px;
          margin-top: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .home-block2 {
    .is-desktop {
      display: none !important;
    }
    .is-mobile {
      display: block;
    }
    .box {
      flex-direction: column;

      .img {
        width: 100%;
      }

      .content {
        width: 100%;
        padding: 48px 20px 36px 20px;

        .content-title {
          font-size: 28px;
        }

        .text {
          font-size: 16px;
          margin-top: 18px;
        }
      }
    }
  }
}
</style>
