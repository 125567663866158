<template>
    <v-overlay
        :value="overlay"
    >
        <v-progress-circular
            indeterminate
            color="#808080"
        />
    </v-overlay>
</template>

<script>
import { mapState } from 'vuex';

export default {
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapState('mLoading', ['overlay']),
    },
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>

</style>
