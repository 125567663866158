<template>
    <div class="nav">
        <div class="content desktop">
            <img
                src="../assets/logo.png"
                alt="logo"
                class="logo"
                @click="goto('Home')"
            >
            <div class="menu is-desktop">
                <router-link
                    v-for="(item, index) in menu"
                    :key="index"
                    :to="`/${choseLanguage}${item.to}`"
                    class="item"
                    :class="{ 'router-active': activeMenuItem === item.name }"
                >
                    <div class="text font-size-small line-height-1-5 font-weight-bold">
                        {{ $t(item.text) }}
                    </div>
                </router-link>
            </div>
            <div class="action is-desktop">
                <router-link
                    v-if="!isLogin"
                    :to="{ name: 'Login' }"
                    class="login font-size-small line-height-1 font-weight-bold color-gray"
                >
                    {{ $t('nav.login') }}
                </router-link>
                <v-menu
                    v-else
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#F97E2B"
                            v-bind="attrs"
                            class="profile-btn"
                            elevation="0"
                            v-on="on"
                        >
                            <v-icon class="profile-icon">
                                mdi-account-circle-outline
                            </v-icon>
                            <span class="user-name">
                                {{
                                    `${userData.data.lastName}${userData.data.firstName}`
                                }}</span>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :to="{ name: 'Profile' }">
                            <v-list-item-title class="color-orange font-bold font-size-small">
                                {{ $t('nav.member') }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout">
                            <v-list-item-title class="color-orange font-bold font-size-small">
                                {{ $t('nav.logout') }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <div class="divide" />
                <select
                    id=""
                    v-model="choseLanguage"
                    name=""
                    class="language font-size-small line-height-1 font-weight-bold"
                    @change="webLanguage"
                >
                    <option value="zhTw">
                        {{ $t('nav.chinese') }}
                    </option>
                    <option value="en">
                        {{ $t('nav.english') }}
                    </option>
                </select>
            </div>
            <div
                class="hamburger is-mobile"
                :class="{ open: mobileMenuShow }"
                @click="clickHamburger"
            >
                <span />
                <span />
                <span />
            </div>
        </div>
        <div
            class="mobile-menu"
            :class="{ open: mobileMenuShow }"
        >
            <div class="mobile-content">
                <div class="member">
                    <router-link
                        v-if="!isLogin"
                        :to="{ name: 'Login' }"
                        class="login line-height-1 font-weight-bold"
                    >
                        {{ $t('nav.login') }}
                    </router-link>
                    <div
                        v-else
                        class="member-detail"
                    >
                        <div class="detail">
                            <v-icon class="profile-icon">
                                mdi-account-circle-outline
                            </v-icon>
                            <span class="user-name">
                                {{
                                    `${userData.data.lastName}${userData.data.firstName}`
                                }}</span>
                        </div>
                        <div class="action">
                            <router-link
                                :to="{ name: 'Profile' }"
                                class="action-item"
                            >
                                {{ $t('nav.member') }}
                            </router-link>
                            <div
                                class="action-item"
                                @click="logout"
                            >
                                {{ $t('nav.logout') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="menu">
                    <router-link
                        v-for="(item, index) in menu"
                        :key="index"
                        :to="`/${choseLanguage}${item.to}`"
                        class="item"
                        :class="{ 'router-active': activeMenuItem === item.name }"
                    >
                        <div class="text line-height-1-5 font-weight-bold">
                            {{ $t(item.text) }}
                        </div>
                    </router-link>
                </div>
                <div class="language">
                    <span
                        class="label line-height-1-5 font-weight-bold"
                    >{{ $t('nav.language') }}<v-icon class="icon">mdi-chevron-right</v-icon>
                    </span>
                    <button
                        :class="{active:choseLanguage==='zhTw',unactive:choseLanguage!=='zhTw'}"
                        class="item line-height-1-5 font-weight-bold"
                        @click="mobileLanguage('zhTw')"
                    >
                        {{ $t('nav.chinese') }}
                    </button>
                    <button
                        :class="{active:choseLanguage==='en',unactive:choseLanguage!=='en'}"
                        class="item line-height-1-5 font-weight-bold"
                        @click="mobileLanguage('en')"
                    >
                        {{ $t('nav.english') }}
                    </button>
                </div>
            </div>
            <div class="footer">
                <div class="sns-box">
                    <a
                        href="https://www.facebook.com/visionfuture.org.tw"
                        target="_blank"
                        class="sns"
                    >
                        <img
                            src="../assets/sns/fb.png"
                            alt=""
                        >
                    </a>
                    <a
                        href="https://www.instagram.com/visionfuturefund/"
                        target="_blank"
                        class="sns"
                    >
                        <img
                            src="../assets/sns/ig.png"
                            alt=""
                        >
                    </a>
                    <a
                        href="https://www.youtube.com/channel/UCtCQpThY5bj9eS5XXIDsp3g"
                        target="_blank"
                        class="sns"
                    >
                        <img
                            src="../assets/sns/YT.png"
                            alt=""
                        >
                    </a>
                    <a
                        href="mailto:hivisionfuture@tzuchi.org.tw"
                        target="_blank"
                        class="sns"
                    >
                        <img
                            src="../assets/sns/mail.png"
                            alt=""
                        >
                    </a>
                </div>
                <div class="terms font-size-small line-height-2 font-weight-bold">
                    <router-link to="">
                        {{ $t('nav.terms') }}
                    </router-link>
                </div>
                <div class="copy-right font-size-small line-height-2 font-weight-bold">
                    Copyright © 2023 {{ $t('nav.copyRight') }} All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    components: {},
    data() {
        return {
            choseLanguage: 'zhTw',
            mobileMenuShow: false,
            activeMenuItem: '',
            menu: [
                {
                    to: '/about',
                    text: 'nav.about',
                    name: 'About',
                },
                {
                    to: '/invention',
                    text: 'nav.invention',
                    name: 'Invention',
                },
                {
                    to: '/incubator',
                    text: 'nav.incubator',
                    name: 'Incubator',
                },
                {
                    to: '/academy',
                    text: 'nav.academy',
                    name: 'Academy',
                },
                {
                    to: '/study',
                    text: 'nav.study',
                    name: 'Study',
                },
                {
                    to: '/news',
                    text: 'nav.news',
                    name: 'News',
                },
            ],
        };
    },
    computed: {
        ...mapState('mAuth', { userData: 'data' }),
        ...mapGetters('mAuth', ['isLogin']),
    },
    watch: {
    // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            this.mobileMenuShow = false;
            this.checkActiveRouter();
        },
    },
    created() {
        this.checkActiveRouter();
    },
    mounted() { },
    methods: {
        ...mapActions('mAuth', ['Logout']),
        mobileLanguage(value) {
            if (value !== this.choseLanguage) {
                this.choseLanguage = value;
                this.$router.push({
                    path: `/${this.choseLanguage}${this.$route.path.replace(/^\/(zhTw|en)/, '')}`,
                });
            }
        },
        webLanguage() {
            this.$router.push({
                path: `/${this.choseLanguage}${this.$route.path.replace(/^\/(zhTw|en)/, '')}`,
            });
        },
        logout() {
            this.Logout();
            this.goto('Home');
        },
        goto(routerName) {
            this.$router.push({ name: routerName });
        },
        clickHamburger() {
            this.mobileMenuShow = !this.mobileMenuShow;
            this.checkActiveRouter();
        },
        checkActiveRouter() {
            switch (this.$route.path.split('/')[1]) {
                case 'en':
                    this.choseLanguage = 'en';
                    this.$i18n.locale = 'en';
                    break;
                default:
                    this.choseLanguage = 'zhTw';
                    this.$i18n.locale = 'zhTw';
                    break;
            }
            switch (this.$route.name) {
                case 'About':
                    this.activeMenuItem = 'About';
                    break;
                case 'Invention':
                    this.activeMenuItem = 'Invention';
                    break;
                case 'Incubator':
                    this.activeMenuItem = 'Incubator';
                    break;
                case 'AcademyLanding': case 'AcademyHistory': case 'AcademyHistoryItem':
                    this.activeMenuItem = 'Academy';
                    break;
                case 'StudyLanding': case 'StudySRIOItem': case 'StudyFUN': case 'StudyFUNItem': case 'StudyHistoryItem':
                    this.activeMenuItem = 'Study';
                    break;
                case 'NewsLanding': case 'NewsNow': case 'NewsNowItem': case 'NewsSocial': case 'NewsSocialItem': case 'NewsTeams': case 'NewsTeamsItem':
                    this.activeMenuItem = 'News';
                    break;
                default:
                    this.activeMenuItem = '';
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.active{
    color: white;
    position: relative;

}
.active::before{
    content: '•';
    position: absolute;
    right: 110%;
}
.unactive{
    color: $color-orange;
}
.nav {
  width: 100vw;
  display: flex;
  justify-content: center;
  border-bottom: 3px solid #f97e2b;
  height: 100px;
  background-color: white;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;

  .profile-btn {
    color: white !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.1em;

    .user-name {
      max-width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .profile-icon {
      margin-right: 5px;
    }
  }

  .content {
    width: 100%;
    max-width: 1250px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .is-mobile {
      display: none;
    }

    .logo {
      width: 140px;
      height: 51px;
      cursor: pointer;
    }

    .menu {
      display: flex;
      height: 100%;
      color: $color-gray;
      .item {
        color: $color-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 125px;
        margin: 0 1px;
        .text {
          text-align: center;
          white-space: pre-line;
        }
      }
      .item:hover {
        color: white;
        background-color: $color-orange;
      }

      .router-active {
        color: white;
        background-color: $color-orange;
        padding: 0 2px;
      }
    }

    .action {
      display: flex;

      .login {
        cursor: pointer;
        text-decoration: none;
        color: #cccccc;
        letter-spacing: 0.1em;
      }

      .divide {
        border: 1px solid #cccccc;
        margin: 0 16px;
      }
      .language {
        color: #cccccc;
        letter-spacing: 0.1em;
        cursor: pointer;
      }
    }
  }

  .mobile-menu {
    letter-spacing: 0.1em;
    position: fixed;
    top: 0;
    left: -100vw;
    z-index: 1000;
    background-color: $color-blue;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    overflow: scroll;
    padding: 90px 60px 30px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    .member-detail {
      .detail {
        color: $color-orange;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.1em;
        margin-bottom: 30px;
        .profile-icon {
          color: $color-orange;
          font-size: 21px;
          margin-right: 5px;
        }
      }
      .action {
        .action-item {
          display: block;
          color: white;
          font-size: 16px;
          font-weight: 700;
          line-height: 1;
          letter-spacing: 0.1em;

          text-decoration: none;
        }
        .action-item:not(:last-child) {
          margin-bottom: 30px;
        }
        .action-item:last-child {
          margin-bottom: 15px;
        }
      }
    }

    .login {
      color: white;
      text-decoration: none;
    }

    .member {
      width: 100%;
      padding-bottom: 15px;
      border-bottom: 1px solid $color-orange;
      color: white;
    }

    .menu {
      margin-top: 30px;
      border-bottom: 1px solid $color-orange;
      display: flex;
      flex-direction: column;
      width: 100%;
      .item {
        text-decoration: none;
        color: $color-orange;
        margin-bottom: 30px;
        font-size: 16px;
      }

      .router-active {
        color: white;
      }
    }

    .language {
      display: flex;
      padding-top: 13px;
      margin-bottom: 30px;
      .label {
        color: $color-orange;
        .icon {
          color: $color-orange;
        }
      }
      .item {
        color: white;
        margin-left: 13px;
      }
    }

    .footer {
      .sns-box {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;

        .sns {
          img {
            width: 40px;
            height: 40px;
          }
        }
        .sns:not(:last-child) {
          margin-right: 32px;
        }
      }
      .terms {
        color: white;
        display: flex;
        justify-content: center;
        margin-bottom: 17px;

        a {
          color: white;
          text-decoration: none;
        }
      }

      .copy-right {
        text-align: center;
        color: white;
      }
    }
  }

  .mobile-menu.open {
    left: 0;
  }
}

@media screen and (max-width: 1280px) {
  .nav {
    .content {
      .is-desktop {
        display: none !important;
      }

      .is-mobile {
        display: block;
      }

      .hamburger {
        width: 35px;
        height: 33px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        z-index: 1001;

        span {
          background-color: $color-orange;
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
          -webkit-transition: 0.25s ease-in-out;
          -moz-transition: 0.25s ease-in-out;
          -o-transition: 0.25s ease-in-out;
          transition: 0.25s ease-in-out;
        }
        span:nth-child(1) {
          top: 0px;
        }
        span:nth-child(2) {
          background-color: $color-blue;
          top: 12px;
        }
        span:nth-child(3) {
          top: 24px;
        }
      }

      .hamburger.open {
        span:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        span:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }

    .mobile-menu {
      .footer {
        .terms {
          font-size: 12px;
        }

        .copy-right {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
