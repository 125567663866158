import _ from 'lodash';
import API from '@/api';
import router from '@/router';

const incubatorApply = {
    namespaced: true,
    state: {
        refersTeamName: '',
        refersRecommendName: '',
        refersPlatformName: '',
        refersOther: '',
        form: {
            team: {
                organizeName: '', // 組織名稱
                type: '', // 參賽組別
                country: '', // 組織登記地區-國家
                city: '', // 組織登記地區-城市
                taxId: '', // 統一編號
                name: '', // 團隊資料
                refers: [],
                members: [{
                    name: '', // 團隊成員-姓名
                    phone: '', // 團隊成員-電話
                    email: '', // 團隊成員-email
                    department: '', // 團隊成員-單位職稱/學校系級
                    birthday: '', // 團隊成員-生日
                },
                ],
            },
            proposal: {
                name: '', // 計畫名稱
                property: '', // 團隊屬性
                abstract: '', // 提案內容
                issue: '', // 議題
                pdfFile: '', // 計畫書
                img: '', // 團隊照片
            },
        },
        status: '',
    },
    mutations: {
        updateRefers(state, value) {
            state.form.team.refers = value;
        },
        updateForm(state, value) {
            state.form = value;
        },
        updateTeam(state, value) {
            state.form.team = _.cloneDeep(value);
        },
        updateProposal(state, value) {
            state.form.proposal = _.cloneDeep(value);
        },
        updateStatus(state, value) {
            state.form.status = value;
        },
        updateRefersTeamName(state, value) {
            state.refersTeamName = value;
        },
        updateRefersRecommendName(state, value) {
            state.refersRecommendName = value;
        },
        updateRefersPlatformName(state, value) {
            state.refersPlatformName = value;
        },
        updateRefersOther(state, value) {
            state.refersOther = value;
        },
        resetForm(state) {
            state.form = {
                team: {
                    organizeName: '', // 組織名稱
                    type: '', // 參賽組別
                    country: '', // 組織登記地區-國家
                    city: '', // 組織登記地區-程式
                    taxId: '', // 統一編號
                    name: '', // 團隊資料
                    refers: [],
                    members: [{
                        name: '', // 團隊成員-姓名
                        phone: '', // 團隊成員-電話
                        email: '', // 團隊成員-email
                        department: '', // 團隊成員-單位職稱/學校系級
                        birthday: '', // 團隊成員-生日
                    },
                    ],
                },
                proposal: {
                    name: '', // 計畫名稱
                    property: '', // 團隊屬性
                    abstract: '', // 提案內容
                    issue: '', // 議題
                    pdfFile: '', // 計畫書
                    img: '', // 團隊照片
                },
            };
        },
    },
    actions: {
        loadApply({ commit }, applyId) {
            commit('mLoading/setLoading', true, { root: true });
            API.IncubatorApply.PrivateFetchResource(applyId)
                .then(async (res) => {
                    // 修改報名來源資料
                    const option1 = '歷屆團隊推薦，團隊名:';
                    const option2 = '親朋好友推薦，推薦人:';
                    const option3 = '媒體宣傳平台，平台名:';
                    const option4 = '其他:';

                    const refersData = res.data.team.refers.map((item) => {
                        if (item.includes(option1)) {
                            const text = item.split(option1)[1] ? item.split(option1)[1] : '';
                            commit('updateRefersTeamName', text);
                            return option1;
                        } if (item.includes(option2)) {
                            const text = item.split(option2)[1] ? item.split(option2)[1] : '';
                            commit('updateRefersRecommendName', text);
                            return option2;
                        } if (item.includes(option3)) {
                            const text = item.split(option3)[1] ? item.split(option3)[1] : '';
                            commit('updateRefersPlatformName', text);
                            return option3;
                        } if (item.includes(option4)) {
                            const text = item.split(option4)[1] ? item.split(option4)[1] : '';
                            commit('updateRefersOther', text);
                            return option4;
                        }
                        return item;
                    });
                    commit('updateTeam', res.data.team);
                    commit('updateRefers', refersData);
                    commit('updateProposal', res.data.proposal);
                    commit('updateStatus', res.data.status);
                    commit('mLoading/setLoading', false, { root: true });
                })
                .catch((e) => {
                    commit('mLoading/setLoading', false, { root: true });
                    commit('mNotification/handleError', {
                        message: e || '資料拿取失敗',
                        color: 'error',
                    }, { root: true });
                    throw e;
                });
        },
        async saveTeam({ commit, dispatch }, data) {
            commit('updateTeam', data.form);
            return new Promise((resolve, reject) => {
                dispatch('saveForm', data.applyId)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        async saveProposal({ commit, dispatch }, data) {
            commit('updateProposal', data.form);
            return new Promise((resolve, reject) => {
                dispatch('saveForm', data.applyId)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        async saveForm({ commit, dispatch }, applyId) {
            const form = await dispatch('convertForm');
            commit('mLoading/setLoading', true, { root: true });
            if (applyId === 'new') {
                return API.IncubatorApply.PrivateCreateResource(form)
                    .then((res) => {
                        router.replace({
                            params: {
                                id: res.data.id,
                            },
                        });
                        commit('mNotification/setAlert', {
                            message: '儲存成功',
                            color: 'success',
                        }, { root: true });
                        commit('mLoading/setLoading', false, { root: true });
                    })
                    .catch((e) => {
                        commit('mLoading/setLoading', false, { root: true });
                        commit('mNotification/handleError', {
                            message: e || '儲存失敗',
                            color: 'error',
                        }, { root: true });
                        throw e;
                    });
            }
            return API.IncubatorApply.PrivateUpdateResource(applyId, form)
                .then(() => {
                    commit('mNotification/setAlert', {
                        message: '儲存成功',
                        color: 'success',
                    }, { root: true });
                    commit('mLoading/setLoading', false, { root: true });
                })
                .catch((e) => {
                    commit('mLoading/setLoading', false, { root: true });
                    commit('mNotification/handleError', {
                        message: e || '儲存失敗',
                        color: 'error',
                    }, { root: true });
                });
        },
        convertForm({ state }) {
            const data = _.cloneDeep(state.form);
            delete data.final;
            return new API.FormData(_.omit(data, ['status']), [{
                urls: _.filter([
                    data.proposal.img,
                    data.proposal.pdfFile,
                ], Boolean),
                ext: (mimetype) => ({
                    'image/png': 'png',
                    'image/jpeg': 'jpg',
                    'application/pdf': 'pdf',
                }[mimetype] || 'png'),
            }]);
        },
    },
    getters: {
        teamData: (state) => _.get(state.form, 'team', {}),
        proposalData: (state) => _.get(state.form, 'proposal', {}),
        formData: (state) => state.form,
    },
};

export default incubatorApply;
