import Anchor from '@/components/Anchor.vue';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueNumber from 'vue-number-animation';
import AOS from 'aos';
import API from '@/api';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import 'aos/dist/aos.css';

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueNumber);

Vue.component('Anchor', Anchor);

Vue.prototype.$bus = new Vue();
Vue.prototype.$bus.$on('updateToken', (token) => {
    store.dispatch('mAuth/Sync', token);
});
Vue.prototype.$static = API.Static;
store.dispatch('mAuth/Sync', localStorage.getItem('token'));

new Vue({
    router,
    store,
    vuetify,
    i18n,
    created() {
        AOS.init({});
    },
    render: (h) => h(App),
}).$mount('#app');
