<template>
    <div class="home-block home-block1">
        <h2
            class="home-block-title"
            data-aos="fade-up"
        >
            {{ $t('home.block1.title') }}
        </h2>
        <h3
            class="home-block-subtitle"
            data-aos="fade-up"
        >
            {{ $t('home.block1.subtitle') }}
        </h3>
        <div class="number-animate-block">
            <div
                v-for="item in numbers"
                :key="item.ref"
                :ref="item.ref"
                class="number-item"
            >
                <div class="number-animate-1">
                    <div class="number-box">
                        <number
                            :ref="item.numberRef"
                            class="number"
                            :from="item.from"
                            :to="item.to"
                            :duration="5"
                            :delay="0"
                            easing="Power1.easeOut"
                            :animation-paused="true"
                        />
                        <div class="unit">
                            {{ item.unit }}
                        </div>
                    </div>
                </div>
                <div class="label">
                    {{ $t(item.label) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            numbers: [
                {
                    ref: 'block-1-1',
                    numberRef: 'block-1-1-number',
                    from: 0,
                    to: 92,
                    unit: '%',
                    label: 'home.block1.numbers[0]',
                },
                {
                    ref: 'block-1-2',
                    numberRef: 'block-1-2-number',
                    from: 0,
                    to: 90,
                    unit: '%',
                    label: 'home.block1.numbers[1]',
                },
                {
                    ref: 'block-1-3',
                    numberRef: 'block-1-3-number',
                    from: 0,
                    to: 90,
                    unit: '%',
                    label: 'home.block1.numbers[2]',
                },
                {
                    ref: 'block-1-4',
                    numberRef: 'block-1-4-number',
                    from: 0,
                    to: 91,
                    unit: '%',
                    label: 'home.block1.numbers[3]',
                },
            ],
        };
    },
    computed: {},
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.handleScroll();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const { scrollY } = window;
            const windowHeight = window.innerHeight;
            const positionY = scrollY + windowHeight / 2;
            this.numbers.forEach((item) => {
                this.handleNumberAnimation(item.ref, item.numberRef, positionY);
            });
        },
        handleNumberAnimation(el, number, scrollY) {
            const elRef = this.$refs[el][0];
            const numberRef = this.$refs[number][0];
            if (scrollY >= elRef.offsetTop) {
                numberRef.play();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/number-animate.scss';
.home-block1 {
  padding: 80px 0 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-block-title{
    margin-bottom: 30px;
  }

  .number-animate-block {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    width: 1062px;

    .number-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .label {
      color: $color-blue;
      text-align: center;
      margin-top: 18px;
      font-size: 21px;
      line-height: 1;
      letter-spacing: 0.1em;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 1160px) {
  .home-block1 {
    padding: 60px 0 40px 0;
    .number-animate-block {
      width: 460px;
      flex-wrap: wrap;
      justify-content: center;
      .number-item {
        margin-bottom: 80px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home-block1 {
    padding: 30px 0 0 0;

    .block-title {
      margin-bottom: 12px;
    }

    .number-animate-block {
      margin-top: 40px;
      width: 310px;

      .number-item {
        margin-bottom: 60px;
      }

      .label {
        margin-top: 12px;
      }
    }
  }
}
</style>
