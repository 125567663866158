import _ from 'lodash';

const notification = {
    namespaced: true,
    state: {
        message: '',
        color: undefined,
    },
    mutations: {
    // eslint-disable-next-line no-shadow
        setAlert(state, payload) {
            state.message = payload.message;
            state.color = payload.color;
        },
        handleError(state, payload) {
            const message = _.get(payload.message, 'response.data.message', payload.message.toString());
            state.message = message;
            state.color = payload.color;
        },
        resetAlert(state) {
            state.message = '';
            state.color = undefined;
        },
    },
};
export default notification;
