<template>
    <div class="anchor-block">
        <div
            :id="id"
            class="anchor"
        />
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        // eslint-disable-next-line vue/require-default-prop
        id: String,
    },
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>

.anchor-block{
  position: relative;
}
.anchor {
  position: absolute;
  top: -150px;
}
</style>
