import _ from 'lodash';
import API from '@/api';
import router from '@/router';

const inventionApply = {
    namespaced: true,
    state: {
        refersTeamName: '',
        refersRecommendName: '',
        refersOther: '',
        form: {
            team: {
                name: '',
                previewImg: '',
                school: '',
                refers: [],
                teachers: [{
                    name: '',
                    title: '',
                    phone: '',
                    email: '',
                }],
                members: [{
                    name: '',
                    phone: '',
                    email: '',
                    department: '',
                    grade: '',
                    pdfFile: '',
                },
                {
                    name: '',
                    phone: '',
                    email: '',
                    department: '',
                    grade: '',
                    pdfFile: '',
                }],
            },
            proposal: {
                name: '',
                level: '',
                main: '',
                subs: [],
                abstract: '',
                pdfFile: '',
            },
            status: '',
        },
    },
    mutations: {
        updateRefers(state, value) {
            state.form.team.refers = value;
        },
        updateTeam(state, value) {
            state.form.team = _.cloneDeep(value);
        },
        updateProposal(state, value) {
            state.form.proposal = _.cloneDeep(value);
        },
        updateStatus(state, value) {
            state.form.status = value;
        },
        updateForm(state, value) {
            state.form = value;
        },
        updateRefersTeamName(state, value) {
            state.refersTeamName = value;
        },
        updateRefersRecommendName(state, value) {
            state.refersRecommendName = value;
        },
        updateRefersOther(state, value) {
            state.refersOther = value;
        },
        resetForm(state) {
            console.log('resetForm');
            state.form = {
                team: {
                    name: '',
                    previewImg: '',
                    school: '',
                    refers: [],
                    teachers: [{
                        name: '',
                        title: '',
                        phone: '',
                        email: '',
                    }],
                    members: [{
                        name: '',
                        phone: '',
                        email: '',
                        department: '',
                        grade: '',
                        pdfFile: '',
                    },
                    {
                        name: '',
                        phone: '',
                        email: '',
                        department: '',
                        grade: '',
                        pdfFile: '',
                    }],
                },
                proposal: {
                    name: '',
                    level: '',
                    main: '',
                    subs: [],
                    abstract: '',
                    pdfFile: '',
                },
                status: '',
            };
        },
    },
    actions: {
        loadApply({ commit }, applyId) {
            commit('mLoading/setLoading', true, { root: true });
            API.InventionApply.PrivateFetchResource(applyId)
                .then((res) => {
                    // 修改報名來源資料
                    const option1 = '歷屆團隊推薦，團隊名:';
                    const option2 = '親朋好友推薦，推薦人:';
                    const option3 = '其他:';
                    const refersData = res.data.team.refers.map((item) => {
                        if (item.includes(option1)) {
                            const text = item.split(option1)[1] ? item.split(option1)[1] : '';
                            commit('updateRefersTeamName', text);
                            return option1;
                        } if (item.includes(option2)) {
                            const text = item.split(option2)[1] ? item.split(option2)[1] : '';
                            commit('updateRefersRecommendName', text);
                            return option2;
                        } if (item.includes(option3)) {
                            const text = item.split(option3)[1] ? item.split(option3)[1] : '';
                            commit('updateRefersOther', text);
                            return option3;
                        }
                        return item;
                    });
                    commit('updateTeam', res.data.team);
                    commit('updateRefers', refersData);
                    commit('updateProposal', res.data.proposal);
                    commit('updateStatus', res.data.status);
                    commit('mLoading/setLoading', false, { root: true });
                })
                .catch((e) => {
                    commit('mLoading/setLoading', false, { root: true });
                    commit('mNotification/handleError', {
                        message: e || '資料拿取失敗',
                        color: 'error',
                    }, { root: true });
                });
        },
        async saveTeam({ commit, dispatch }, data) {
            commit('updateTeam', data.form);
            return new Promise((resolve, reject) => {
                dispatch('saveForm', data.applyId)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        async saveProposal({ commit, dispatch }, data) {
            commit('updateProposal', data.form);
            return new Promise((resolve, reject) => {
                dispatch('saveForm', data.applyId)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        async saveForm({ commit, dispatch }, applyId) {
            const form = await dispatch('convertForm');
            commit('mLoading/setLoading', true, { root: true });
            if (applyId === 'new') {
                return API.InventionApply.PrivateCreateResource(form)
                    .then((res) => {
                        router.replace({
                            params: {
                                id: res.data.id,
                            },
                        });
                        commit('mNotification/setAlert', {
                            message: '儲存成功',
                            color: 'success',
                        }, { root: true });
                        commit('mLoading/setLoading', false, { root: true });
                    })
                    .catch((e) => {
                        commit('mLoading/setLoading', false, { root: true });
                        commit('mNotification/handleError', {
                            message: e || '儲存失敗',
                            color: 'error',
                        }, { root: true });
                    });
            }
            return API.InventionApply.PrivateUpdateResource(applyId, form)
                .then(() => {
                    commit('mNotification/setAlert', {
                        message: '儲存成功',
                        color: 'success',
                    }, { root: true });
                    commit('mLoading/setLoading', false, { root: true });
                })
                .catch((e) => {
                    commit('mLoading/setLoading', false, { root: true });
                    commit('mNotification/handleError', {
                        message: e || '儲存失敗',
                        color: 'error',
                    }, { root: true });
                });
        },
        convertForm({ state }) {
            const data = _.cloneDeep(state.form);
            data.team.members.forEach((m) => {
                // eslint-disable-next-line no-param-reassign
                delete m.tmpPdf;
            });
            return new API.FormData(_.omit(data, ['status']), [{
                urls: _.filter([
                    data.team.previewImg,
                    ..._.map(data.team.members, 'pdfFile'),
                    data.proposal.pdfFile,
                ], Boolean),
                ext: (mimetype) => ({
                    'image/png': 'png',
                    'image/jpeg': 'jpg',
                    'application/pdf': 'pdf',
                }[mimetype] || 'png'),
            }]);
        },
    },
    getters: {
        teamData: (state) => _.get(state.form, 'team', {}),
        proposalData: (state) => _.get(state.form, 'proposal', {}),
        formData: (state) => state.form,
    },
};

export default inventionApply;
