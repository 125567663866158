const loading = {
    namespaced: true,
    state: {
        overlay: false,
    },
    mutations: {
    // eslint-disable-next-line no-shadow
        setLoading(state, payload) {
            state.overlay = payload;
        },
    },
};
export default loading;
