import _ from 'lodash';
import axios from 'axios';

export default (getOptions) => ({
    Submit: (id) => axios.post(`/api/invention/apply/pvt/submit/${id}`, {}, getOptions()),
    Download: (params) => axios.get('/api/invention/apply/pvt/download/list', _.merge(getOptions(), {
        responseType: 'blob',
        params: _.pick(params, ['filter', 'search']),
    })),
});
