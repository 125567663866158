import Vue from 'vue';
import axios from 'axios';
import FormData from '@/api/libs/form-data';
import UserAPI from '@/api/libs/user';
import ResourceAPI from '@/api/libs/resource';
import SettingAPI from '@/api/libs/setting';
import MailchimpAPI from './mailchimp-utils';
import InventionApply from './invention-apply-utils';
import IncubatorApply from './incubator-apply-utils';
import InventionSession from './invention-session-utils';
import IncubatorSession from './incubator-session-utils';
import UserUtil from './user-utils';
//

const baseURL = process.env.VUE_APP_BASE_URL
    ? `https://${process.env.VUE_APP_BASE_URL}`
    : `https://${window.location.hostname}:8080`;
const getOptions = () => {
    const token = localStorage.getItem('token');
    return {
        baseURL,
        headers: {
            ...token ? { authorization: `Bearer ${token}` } : {},
        },
    };
};

axios.interceptors.response.use((response) => response, (error) => {
    if (error.response?.status === 401) {
        Vue.prototype.$bus.$emit('updateToken', '');
    }
    return Promise.reject(error);
});

const API = {
    Manager: new UserAPI('manager', getOptions),
    User: new UserAPI('user', getOptions),
    UserResource: new ResourceAPI('user/resource', getOptions),
    UserUtil: new UserUtil(getOptions),
    Setting: new SettingAPI('setting', getOptions),
    //
    InventionApply: new ResourceAPI('invention/apply', getOptions),
    InventionApplyUtil: InventionApply(getOptions),
    InventionSession: new ResourceAPI('invention/session', getOptions),
    InventionSessionUtil: InventionSession(getOptions),
    InventionContent: new ResourceAPI('invention/content', getOptions),
    InventionPrize: new ResourceAPI('invention/prize', getOptions),
    InventionQA: new ResourceAPI('invention/qa', getOptions),
    InventionRecommend: new ResourceAPI('invention/recommend', getOptions),
    InventionTeam: new ResourceAPI('invention/team', getOptions),
    //
    IncubatorApply: new ResourceAPI('incubator/apply', getOptions),
    IncubatorApplyUtil: IncubatorApply(getOptions),
    IncubatorSession: new ResourceAPI('incubator/session', getOptions),
    IncubatorSessionUtil: IncubatorSession(getOptions),
    IncubatorContent: new ResourceAPI('incubator/content', getOptions),
    IncubatorQA: new ResourceAPI('incubator/qa', getOptions),
    IncubatorRecommend: new ResourceAPI('incubator/recommend', getOptions),
    IncubatorTeacher: new ResourceAPI('incubator/teacher', getOptions),
    IncubatorTeam: new ResourceAPI('incubator/team', getOptions),
    //
    HomeBanner: new ResourceAPI('home/banner', getOptions),
    HomeRecommend: new ResourceAPI('home/recommend', getOptions),
    //
    EducationBanner: new ResourceAPI('education/banner', getOptions),
    EducationThink: new ResourceAPI('education/think', getOptions),
    EducationWalk: new ResourceAPI('education/walk', getOptions),
    EducationHistory: new ResourceAPI('education/history', getOptions),
    //
    BookBanner: new ResourceAPI('book/banner', getOptions),
    BookSori: new ResourceAPI('book/sori', getOptions),
    BookReport: new ResourceAPI('book/report', getOptions),
    BookHistory: new ResourceAPI('book/history', getOptions),
    //
    ArticleBanner: new ResourceAPI('article/banner', getOptions),
    ArticleTag: new ResourceAPI('article/tag', getOptions),
    Article: new ResourceAPI('article', getOptions),
    //
    Mailchimp: MailchimpAPI(getOptions),
    Static: (url) => (/^(blob|http)/.test(url) ? url : `${baseURL}${url}`),
    FormData,
};

function AutoRefreshToken(role, intervalTime) {
    role.AutoRefreshToken().catch(() => { });
    setInterval(() => {
        role.AutoRefreshToken().catch(() => { });
    }, intervalTime);
}

AutoRefreshToken(API.Manager, 60 * 60 * 1000); // Manager 一小時檢查一次 Token
AutoRefreshToken(API.User, 60 * 60 * 1000); // User 一小時檢查一次 Token
export default API;
