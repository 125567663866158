<template>
    <div class="sns-fixed">
        <a
            class="sns"
            href="https://www.facebook.com/visionfuture.org.tw"
            target="_blank"
        ><img
            class="image"
            src="../assets/sns/fb.png"
            alt=""
        ></a>
        <a
            class="sns"
            href="https://www.instagram.com/visionfuturefund/"
            target="_blank"
        ><img
            class="image"
            src="../assets/sns/ig.png"
            alt=""
        ></a>
        <a
            class="sns"
            href="https://www.youtube.com/channel/UCtCQpThY5bj9eS5XXIDsp3g"
            target="_blank"
        ><img
            class="image"
            src="../assets/sns/YT.png"
            alt=""
        ></a>
        <a
            class="sns"
            href="mailto:hivisionfuture@tzuchi.org.tw"
            target="_blank"
        ><img
            class="image"
            src="../assets/sns/mail.png"
            alt=""
        ></a>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.sns-fixed {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 30;

  .sns {
    .image {
      width: 40px;
      height: 40px;
    }

  }
  .sns:not(:last-child) {
      margin-bottom: 15px;
    }
}
</style>
