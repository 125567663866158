<template>
    <v-snackbar
        class="text-center"
        :timeout="5000"
        :value="!!message"
        :color="color"
        top
        @input="closeAlert"
    >
        {{ message }}
    </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapState('mNotification', ['message', 'color']),
    },
    created() {},
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['resetAlert']),
        closeAlert() {
            this.resetAlert();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
