<template>
    <div class="home-block home-block6">
        <img
            class="left is-desktop"
            src="../../assets/home/block-6-left.jpg"
            alt=""
        >
        <img
            class="left is-mobile"
            src="../../assets/home/mobile/block-6-left-mobile.jpg"
            alt=""
        >
        <div class="right">
            <router-link
                :to="{name: 'About'}"
                class="button"
                data-aos="fade-up"
            >
                {{ $t('home.block6.buttonName[0]') }} <v-icon class="arrow">
                    mdi-arrow-right
                </v-icon>
            </router-link>
            <router-link
                :to="{name: 'SubscribeEDM'}"
                class="button"
                data-aos="fade-up"
            >
                {{ $t('home.block6.buttonName[1]') }} <v-icon class="arrow">
                    mdi-arrow-right
                </v-icon>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.home-block6 {
  display: flex;

  .is-mobile {
    display: none;
  }
  .left {
    width: 50%;
  }

  .right {
    width: 50%;
    background-color: $color-orange;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      width: 340px;
      height: 67px;
      border: 2px solid #ffffff;
      border-radius: 290px;
      color: white;
      font-size: 21px;
      font-weight: 700;
      letter-spacing: 0.15em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      padding: 0 40px;

      .arrow {
        color: white;
        font-size: 30px;
      }
    }

    .button:first-child {
      margin-bottom: 38px;
    }

    .button:hover {
      background-color: $color-gray;
    }
  }
}
@media screen and (max-width: 960px) {
  .home-block6 {
    flex-direction: column;
    .is-desktop {
      display: none !important;
    }
    .is-mobile {
      display: block;
    }

    .left {
      width: 100vw;
    }

    .right {
      width: 100vw;
      padding: 80px;
    }
  }
}

@media screen and (max-width: 600px) {
  .home-block6 {
    .right {
      padding: 47px;

      .button {
        width: 210px;
        height: 41px;
        font-size: 12px;
        padding: 0 20px;

        .arrow {
          font-size: 24px;
        }
      }

      .button:first-child {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
