<template>
    <div class="home-block home-block7">
        <div
            class="text"
            data-aos="fade-up"
        >
            {{ $t('home.block7.text') }}
        </div>
        <div
            id="home-block-7-lottie"
            :ref="'block-7-lottie'"
            class="lottie"
        />
    </div>
</template>

<script>
import lottie from 'lottie-web';

export default {
    components: {},
    data() {
        return {
        };
    },
    computed: {},
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.lottieInit();
        this.handleScroll();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        lottieInit() {
            lottie.loadAnimation({
                container: document.getElementById('home-block-7-lottie'), // the dom element that will contain the animation
                renderer: 'svg',
                loop: false,
                autoplay: false,
                name: 'block-7',
                path: '/animation/block-7.json', // the path to the animation json
            });
        },
        handleScroll() {
            const { scrollY } = window;
            const windowHeight = window.innerHeight;
            const positionY = scrollY + windowHeight;
            const elRef = this.$refs['block-7-lottie'];
            if (positionY >= elRef.offsetTop) {
                lottie.play('block-7');
                window.removeEventListener('scroll', this.handleScroll);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.home-block7 {
  background-image: url('../../assets/home/block-bg-bottom.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 100px 150px 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    color: $color-blue;
    font-weight: 700;
    font-size: 28px;
    line-height: 2;
    letter-spacing: 0.1em;
    width: 50%;
    padding-right: 130px;
  }

  .lottie {
    width: 50%;
  }
}

@media screen and (max-width: 1080px) {
  .home-block7 {
    flex-direction: column;
    padding: 100px 60px 80px 60px;
    .text {
      width: 100%;
      text-align: center;
      padding-right: 0;
      margin-bottom: 50px;
    }

    .lottie {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .home-block7 {
    flex-direction: column;
    padding: 80px 20px 60px 20px;
    .text {
      width: 90vw;
      margin-bottom: 35px;
      font-size: 20px;
      line-height: 30px;
    }
  }
}
</style>
