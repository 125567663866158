<template>
    <div class="home-block home-block4">
        <div
            class="home-block-title"
            data-aos="fade-up"
        >
            {{ $t('home.block4.title') }}
        </div>
        <div class="number-animate-block">
            <div
                v-for="item in numbers"
                :key="item.ref"
                :ref="item.ref"
                class="number-item"
            >
                <div class="number-animate-2">
                    <div class="number-box">
                        <number
                            :ref="item.numberRef"
                            class="number"
                            :from="item.from"
                            :to="item.to"
                            :duration="5"
                            :delay="0"
                            easing="Power1.easeOut"
                            :animation-paused="true"
                            :format="item.format"
                        />
                        <div class="unit">
                            {{ $t(item.unit) }}
                        </div>
                    </div>
                </div>
                <div class="label">
                    {{ $t(item.label) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            numbers: [
                {
                    ref: 'block-4-1',
                    numberRef: 'block-4-1-number',
                    from: 0,
                    to: 33,
                    unit: '',
                    label: 'home.block4.label[0]',
                },
                {
                    ref: 'block-4-2',
                    numberRef: 'block-4-2-number',
                    from: 0,
                    to: 5000,
                    unit: '+',
                    label: 'home.block4.label[1]',
                    format(num) { return num.toFixed(0).toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,'); },
                },
                {
                    ref: 'block-4-3',
                    numberRef: 'block-4-3-number',
                    from: 0,
                    to: 4.6,
                    unit: 'M+',
                    label: 'home.block4.label[2]',
                    format(num) { return num.toFixed(1); },
                },
            ],
        };
    },
    computed: {},
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.handleScroll();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const { scrollY } = window;
            const windowHeight = window.innerHeight;
            const positionY = scrollY + windowHeight;
            this.numbers.forEach((item) => {
                this.handleNumberAnimation(item.ref, item.numberRef, positionY);
            });
        },
        handleNumberAnimation(el, number, scrollY) {
            const elRef = this.$refs[el][0];
            const numberRef = this.$refs[number][0];
            if (scrollY >= elRef.offsetTop) {
                numberRef.play();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/number-animate.scss';
.home-block4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 0;
  background-color: #FF9851;
  .home-block-title {
    margin-bottom: 80px;
    color: white !important;
  }

  .number-animate-block {
    display: flex;
    justify-content: space-between;
    width: 960px;

    .number-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .label {
      color: $color-blue;
      font-weight: 700;
      font-size: 21px;
      line-height: 1;
      letter-spacing: 0.1em;
      text-align: center;
      margin-top: 18px;
      color: white;
    }
  }
}

@media screen and (max-width: 1080px) {
  .home-block4 {
    padding: 100px 0 20px 0;
    .number-animate-block {
      flex-direction: column;
      width: 100vw;

      .number-item {
        margin-bottom: 80px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home-block4 {
    padding: 60px 0 0 0;
    .block-title {
      margin-bottom: 60px;
    }
    .number-animate-block {
      .number-item {
        margin-bottom: 60px;
      }

      .label {
        font-size: 16px;
        margin-top: 16px;
      }
    }
  }
}
</style>
