<template>
    <footer class="footer">
        <div class="content">
            <div class="left">
                <div class="top">
                    <img
                        class="top-logo"
                        src="../assets/footer-logo.png"
                        alt=""
                    >
                    <div
                        class="footer-scroll-top"
                        @click="scrollTop"
                    >
                        <v-icon class="top-icon">
                            mdi-arrow-up
                        </v-icon>
                        <div class="text">
                            ＴＯＰ
                        </div>
                    </div>
                </div>
                <div class="menu is-desktop">
                    <div
                        v-for="group in menuGroups"
                        :key="group.top.text"
                        class="group"
                    >
                        <div class="top">
                            <a
                                class="top-link"
                                :href="`/${choseLanguage}${group.top.to}`"
                            >
                                {{ $t(group.top.text ) }}
                            </a>
                        </div>
                        <div class="list">
                            <router-link
                                v-for="item in group.list"
                                :key="group.top.text + item.text"
                                class="item"
                                :to="`/${choseLanguage}${item.to}`"
                            >
                                {{ $t(item.text) }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="logo-item">
                        <div class="label">
                            {{ $t('footer.label[0]') }}｜
                        </div>
                        <img
                            class="bottom-logo-1"
                            src="../assets/footer-bottom-logo-1.png"
                            alt=""
                        >
                    </div>
                    <div class="logo-item">
                        <div class="label">
                            {{ $t('footer.label[1]') }}｜
                        </div>
                        <img
                            class="bottom-logo-2"
                            src="../assets/footer-bottom-logo-2.png"
                            alt=""
                        >
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="top">
                    <div class="sns-box">
                        <a
                            class="sns"
                            target="_blank"
                            href="https://www.facebook.com/visionfuture.org.tw"
                        >
                            <img
                                src="../assets/sns/fb-footer.png"
                                alt=""
                            >
                        </a>
                        <a
                            class="sns"
                            target="_blank"
                            href="https://www.instagram.com/visionfuturefund/"
                        >
                            <img
                                src="../assets/sns/ig-footer.png"
                                alt=""
                            >
                        </a>
                        <a
                            class="sns"
                            target="_blank"
                            href="https://www.youtube.com/channel/UCtCQpThY5bj9eS5XXIDsp3g"
                        >
                            <img
                                src="../assets/sns/YT-footer.png"
                                alt=""
                            >
                        </a>
                        <a
                            class="sns"
                            target="_blank"
                            href="mailto:hivisionfuture@tzuchi.org.tw"
                        >
                            <img
                                src="../assets/sns/mail-footer.png"
                                alt=""
                            >
                        </a>
                    </div>
                </div>
                <div class="bottom">
                    <div class="terms">
                        <router-link :to="{ name: 'PolicyAndTerms' }">
                            {{ $t('footer.terms') }}
                        </router-link>
                    </div>
                    <div class="copy-right">
                        Copyright © 2023 {{ $t('footer.copyRight') }} All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            choseLanguage: 'zhTw',
            menuGroups: [
                {
                    top: {
                        text: 'footer.item1.top',
                        to: '/about',
                    },
                    list: [
                        {
                            text: 'footer.item1.list[0]',
                            to: '/about',
                        },
                        {
                            text: 'footer.item1.list[1]',
                            to: '/about#four-main',
                        },
                        {
                            text: 'footer.item1.list[2]',
                            to: '/about#partner',
                        },
                    ],
                },
                {
                    top: {
                        text: 'footer.item2.top',
                        to: '/invention',
                    },
                    list: [
                        {
                            text: 'footer.item2.list[0]',
                            to: '/invention#explain',
                        },
                        {
                            text: 'footer.item2.list[1]',
                            to: '/invention/#registration',
                        },
                        {
                            text: 'footer.item2.list[2]',
                            to: '/invention',
                        },
                        {
                            text: 'footer.item2.list[3]',
                            to: '/invention#q-and-a',
                        },
                        {
                            text: 'footer.item2.list[4]',
                            to: '/invention#recommend',
                        },
                        {
                            text: 'footer.item2.list[5]',
                            to: '/invention#history-team',
                        },
                    ],
                },
                {
                    top: {
                        text: 'footer.item3.top',
                        to: '/incubator',
                    },
                    list: [
                        {
                            text: 'footer.item3.list[0]',
                            to: '/incubator#explain',
                        },
                        {
                            text: 'footer.item3.list[1]',
                            to: '/incubator#qualification',
                        },
                        {
                            text: 'footer.item3.list[2]',
                            to: '/incubator#q-and-a',
                        },
                        {
                            text: 'footer.item3.list[3]',
                            to: '/incubator#recommend',
                        },
                        {
                            text: 'footer.item3.list[4]',
                            to: '/incubator#history-teacher',
                        },
                        {
                            text: 'footer.item3.list[5]',
                            to: '/incubator#history-team',
                        },
                    ],
                },
                {
                    top: {
                        text: 'footer.item4.top',
                        to: '/academy',
                    },
                    list: [
                        {
                            text: 'footer.item4.list[0]',
                            to: '/academy#curriculum',
                        },
                        {
                            text: 'footer.item4.list[1]',
                            to: '/academy#block',
                        },
                        {
                            text: 'footer.item4.list[2]',
                            to: '/academy#history',
                        },
                    ],
                },
                {
                    top: {
                        text: 'footer.item5.top',
                        to: '/study',
                    },
                    list: [
                        {
                            text: 'footer.item5.list[0]',
                            to: '/study#SROI',
                        },
                        {
                            text: 'footer.item5.list[1]',
                            to: '/study#FUN',
                        },
                        {
                            text: 'footer.item5.list[2]',
                            to: '/study#history',
                        },
                    ],
                },
                {
                    top: {
                        text: 'footer.item6.top',
                        to: '/news',
                    },
                    list: [
                        {
                            text: 'footer.item6.list[0]',
                            to: '/news#now',
                        },
                        {
                            text: 'footer.item6.list[1]',
                            to: '/news#social-innovation',
                        },
                        {
                            text: 'footer.item6.list[2]',
                            to: '/news#teams',
                        },
                    ],
                },
            ],
        };
    },
    computed: {},
    watch: {
    // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            this.init();
        },
    },
    created() {
        this.init();
    },
    mounted() { },
    methods: {
        init() {
            switch (this.$route.path.split('/')[1]) {
                case 'en':
                    this.choseLanguage = 'en';
                    this.$i18n.locale = 'en';
                    break;
                default:
                    this.choseLanguage = 'zhTw';
                    this.$i18n.locale = 'zhTw';
                    break;
            }
        },
        scrollTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $color-gray;
  padding: 50px 80px 25px 80px;
  z-index: 20;
  display: flex;
  justify-content: center;

  .content {
    width: 100%;
    max-width: 1250px;
    display: flex;
    justify-content: space-between;
    .left {
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        .top-logo {
          width: 150px;
          height: 55px;
        }
        .footer-scroll-top {
          display: none;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          .top-icon {
            color: white;
            font-size: 12px;
          }
          .text {
            font-size: 12px;
            color: white;
          }
        }
      }

      .menu {
        display: flex;
        color: white;

        a {
          color: white;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }

        .group {
          .top {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 12px;
            letter-spacing: 0.1em;
            line-height: 1;
          }
          .list {
            font-weight: 500;
            font-size: 16px;
            line-height: 2;
            letter-spacing: 0.1em;
            display: flex;
            flex-direction: column;
          }
        }

        .group:not(:last-child) {
          margin-right: 25px;
        }
        margin-bottom: 45px;
      }

      .bottom {
        display: flex;

        .logo-item {
          display: flex;
          align-items: flex-end;
        }

        .label {
          color: white;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.1em;
          line-height: 1;
          display: flex;
          align-items: flex-end;
          margin-right: 8px;
        }

        .bottom-logo-1 {
          width: 183px;
          height: 29px;
          margin-right: 50px;
        }

        .bottom-logo-2 {
          width: 84px;
          height: 40px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: white;

      .top {
        display: flex;
        justify-content: flex-end;

        .sns-box {
          .sns {
            img {
              width: 40px;
              height: 40px;
            }
          }

          .sns:not(:last-child) {
            margin-right: 15px;
          }
        }
      }

      .bottom {
        .terms {
          font-weight: 700;
          font-size: 14px;
          display: flex;
          margin-bottom: 14px;
          letter-spacing: 0.1em;
          justify-content: flex-end;

          a {
            color: white;
            text-decoration: none;
            display: flex;
            align-items: center;
          }
        }
        .terms:hover {
          a {
            text-decoration: underline;
          }
        }
      }

      .copy-right {
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.1em;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .footer {
    padding: 30px;
    .is-desktop {
      display: none !important;
    }
    .is-mobile {
      display: block;
    }

    .content {
      flex-direction: column;

      .left {
        .bottom {
          flex-direction: column;

          .logo-item {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 30px;

            .label {
              margin-bottom: 5px;
            }
          }
        }
      }

      .right {
        .top {
          flex-direction: column;
        }

        .bottom {
          .terms {
            margin-bottom: 14px;
            justify-content: flex-start;
            margin-top: 32px;
          }
        }
        .copy-right {
          justify-content: flex-start;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .footer {
    .content {
      .left {
        .top {
          .footer-scroll-top {
            display: flex;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    .content {
      .left {
        .top {
          margin-bottom: 32px;
        }
        .bottom {
          .logo-item {
            .label {
              font-size: 12px;
            }
          }
        }
      }
      .right {
        .top {
          .terms {
            font-size: 12px;
          }
        }
        .copy-right {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
