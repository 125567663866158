import Vue from 'vue';
import VueRouter from 'vue-router';
import VueGtm from '@gtm-support/vue2-gtm';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/google-login',
        name: 'GoogleLogin',
        component: () => import('../views/Auth/GoogleLogin.vue'),
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: () => import('../views/Auth/ResetPassword.vue'),
    },
    {
        path: '/:lang(zhTw|en)',
        component: {
            render(c) {
                return c('router-view');
            },
        },
        children: [
            {
                path: '',
                name: 'Home',
                component: Home,
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import('../views/Auth/Login.vue'),
            },
            {
                path: 'register',
                name: 'Register',
                component: () => import('../views/Register/Register.vue'),
                children: [
                    {
                        path: 'member-data',
                        name: 'RegisterMemberData',
                        component: () => import('../views/Register/MemberData.vue'),
                    },
                    {
                        path: 'email-validate',
                        name: 'RegisterEmailValidate',
                        component: () => import('../views/Register/EmailValidate.vue'),
                    },
                    {
                        path: 'success',
                        name: 'RegisterValidateSuccess',
                        component: () => import('../views/Register/ValidateSuccess.vue'),
                    },
                ],
            },
            {
                path: 'user',
                name: 'User',
                component: () => import('../views/User/User.vue'),
                children: [
                    {
                        path: 'profile',
                        name: 'Profile',
                        component: () => import('../views/User/Profile.vue'),
                    },
                    {
                        path: 'change-password',
                        name: 'ChangePassword',
                        component: () => import('../views/User/ChangePassword.vue'),
                    },
                    // 青年公益競賽列表
                    {
                        path: 'incubator-list',
                        name: 'UserIncubator',
                        component: () => import('../views/User/UserIncubatorList.vue'),
                    },
                    // 慈悲科技競賽列表
                    {
                        path: 'invention-list',
                        name: 'UserInvention',
                        component: () => import('../views/User/UserInventionList.vue'),
                    },
                ],
            },
            {
                path: 'forget-password',
                name: 'ForgetPassword',
                component: () => import('../views/Auth/ForgetPassword.vue'),
            },
            {
                path: 'reset-password/:token',
                name: 'ResetPassword',
                component: () => import('../views/Auth/ResetPassword.vue'),
            },
            {
                path: 'about',
                name: 'About',
                component: () => import('../views/About.vue'),
            },
            {
                path: 'invention',
                name: 'Invention',
                component: () => import('../views/Invention.vue'),
            },
            {
                path: 'invention-apply',
                name: 'InventionApply',
                component: () => import('../views/InventionApply/Invention.vue'),
                children: [
                    {
                        path: 'team-data/:id',
                        name: 'InventionTeamData',
                        component: () => import('../views/InventionApply/TeamData.vue'),
                    },
                    {
                        path: 'proposal/:id',
                        name: 'InventionProposal',
                        component: () => import('../views/InventionApply/Proposal.vue'),
                    },
                    {
                        path: 'terms/:id',
                        name: 'InventionTerms',
                        component: () => import('../views/InventionApply/Terms.vue'),
                    },
                    {
                        path: 'confirm/:id',
                        name: 'InventionConfirm',
                        component: () => import('../views/InventionApply/Confirm.vue'),
                    },
                    {
                        path: 'complete/:id',
                        name: 'InventionComplete',
                        component: () => import('../views/InventionApply/Complete.vue'),
                    },
                ],
            },
            {
                path: 'incubator',
                name: 'Incubator',
                component: () => import('../views/Incubator.vue'),
            },
            {
                path: 'incubator-apply',
                name: 'IncubatorApply',
                component: () => import('../views/IncubatorApply/Index.vue'),
                children: [
                    {
                        path: 'team-data/:id',
                        name: 'IncubatorTeamData',
                        component: () => import('../views/IncubatorApply/TeamData.vue'),
                    },
                    {
                        path: 'proposal/:id',
                        name: 'IncubatorProposal',
                        component: () => import('../views/IncubatorApply/Proposal.vue'),
                    },
                    {
                        path: 'confirm/:id',
                        name: 'IncubatorConfirm',
                        component: () => import('../views/IncubatorApply/Confirm.vue'),
                    },
                    {
                        path: 'complete/:id',
                        name: 'IncubatorComplete',
                        component: () => import('../views/IncubatorApply/Complete.vue'),
                    },
                ],
            },
            {
                path: 'incubator-final',
                name: 'IncubatorFinal',
                component: () => import('../views/IncubatorFinal/Index.vue'),
                children: [
                    {
                        path: 'upload/:id',
                        name: 'IncubatorFinalUpload',
                        component: () => import('../views/IncubatorFinal/Upload.vue'),
                    },
                    {
                        path: 'complete/:id',
                        name: 'IncubatorFinalComplete',
                        component: () => import('../views/IncubatorFinal/Complete.vue'),
                    },
                ],
            },
            // 公益教育
            {
                path: 'academy',
                name: 'AcademyIndex',
                component: () => import('../views/Academy/Index.vue'),
                redirect: { name: 'AcademyLanding' },
                children: [
                    {
                        path: 'landing',
                        name: 'AcademyLanding',
                        component: () => import('../views/Academy/Landing.vue'),
                    },
                    {
                        path: 'history',
                        name: 'AcademyHistory',
                        component: () => import('../views/Academy/History.vue'),
                    },
                    {
                        path: 'history/:id',
                        name: 'AcademyHistoryItem',
                        component: () => import('../views/Academy/HistoryItem.vue'),
                    },
                ],
            },
            // 公益研究
            {
                path: 'study',
                name: 'StudyIndex',
                component: () => import('../views/Study/Index.vue'),
                redirect: { name: 'StudyLanding' },
                children: [
                    {
                        path: 'landing',
                        name: 'StudyLanding',
                        component: () => import('../views/Study/Landing.vue'),
                    },
                    {
                        path: 'SRIO',
                        name: 'StudySRIOItem',
                        component: () => import('../views/Study/SRIOItem.vue'),
                    },
                    {
                        path: 'FUN',
                        name: 'StudyFUN',
                        component: () => import('../views/Study/FUN.vue'),
                    },
                    {
                        path: 'FUN/:id',
                        name: 'StudyFUNItem',
                        component: () => import('../views/Study/FUNItem.vue'),
                    },
                    {
                        path: 'history/:id',
                        name: 'StudyHistoryItem',
                        component: () => import('../views/Study/HistoryItem.vue'),
                    },
                ],
            },
            // 公益資訊
            {
                path: 'news',
                name: 'NewsIndex',
                component: () => import('../views/News/Index.vue'),
                redirect: { name: 'NewsLanding' },
                children: [
                    {
                        path: 'landing',
                        name: 'NewsLanding',
                        component: () => import('../views/News/Landing.vue'),
                    },
                    {
                        path: 'now',
                        name: 'NewsNow',
                        component: () => import('../views/News/Now.vue'),
                    },
                    {
                        path: 'now/:id',
                        name: 'NewsNowItem',
                        component: () => import('../views/News/NowItem.vue'),
                    },
                    {
                        path: 'social',
                        name: 'NewsSocial',
                        component: () => import('../views/News/Social.vue'),
                    },
                    {
                        path: 'social/:id',
                        name: 'NewsSocialItem',
                        component: () => import('../views/News/SocialItem.vue'),
                    },
                    {
                        path: 'teams',
                        name: 'NewsTeams',
                        component: () => import('../views/News/Teams.vue'),
                    },
                    {
                        path: 'teams/:id',
                        name: 'NewsTeamsItem',
                        component: () => import('../views/News/TeamsItem.vue'),
                    },
                ],
            },
            {
                path: 'subscribe-edm',
                name: 'SubscribeEDM',
                component: () => import('../views/SubscribeEDM.vue'),
            },
            {
                path: 'policy-terms',
                name: 'PolicyAndTerms',
                component: () => import('../views/PolicyAndTerms.vue'),
            },
            {
                path: 'building',
                name: 'Building',
                component: () => import('../views/Building.vue'),
            },
            {
                path: 'unpublic',
                name: 'UnPublic',
                component: () => import('../views/UnPublic.vue'),
            },
        ],
    },
    {
        path: '/*',
        redirect: '/zhTw/',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    },
});

Vue.use(VueGtm, {
    // container id
    id: 'GTM-KV3GPNG',
    // enable track or not (optional)
    // enabled: process.env.NODE_ENV === 'production',
    // display console logs debugs or not (optional)
    debug: process.env.NODE_ENV !== 'production',
    vueRouter: router,
});

export default router;
